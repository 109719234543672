import Cookies from 'js-cookie';

import '../scss/theme.scss';

import 'bootstrap/js/dist/util';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/popover';
import './components/sticky-header.js';

import '../image/SELF-logo.svg';
import '../image/SELF-logo-white.svg';
import '../image/cutaway-img3.svg';
import '../image/icon/cat-icon-solar.svg';
import '../image/icon/cat-icon-sealing.svg';
import '../image/icon/cat-icon-ac.svg';
import '../image/icon/cat-icon-weather.svg';
import '../image/icon/cat-icon-water.svg';
import '../image/icon/cat-icon-disability.svg';
import '../image/icon/cat-icon-roofing.svg';
import '../image/icon/cat-icon-health.svg';
import '../image/icon/cat-icon-lighting.svg';
import '../image/icon/cat-icon-other.svg';

import '../image/us-map.svg';

import '../image/CDFI_Fund_logo-sm.png';

jQuery(window).on('load',function(){
	//Load Modal
	if(Cookies.get('co_notice')) {
	} else {
		setTimeout(function(){
			jQuery('#modalNotice').modal('show');
		}, 3000);
	}

	//Load Topbar
	if(Cookies.get('co_topbar')) {
	} else {
		jQuery('#topBarCollapse').collapse('show');
	}
});


jQuery(document).ready(function (jQuery) {
	//Elements to inject
	var elementsToInject = document.querySelectorAll('.inject-me');
	// Options
	var injectorOptions = {
		evalScripts: 'once'
	};
	// Trigger the injection
	var injector = new SVGInjector(injectorOptions);
	if (jQuery(elementsToInject).length) {
		injector.inject(
			elementsToInject
		);
	}

	//Modal Notice Cookie
	jQuery('#modalNotice').on('hidden.bs.modal', function(e) {
		Cookies.set('co_notice', 'set', { expires: 1 });
	});

	//Topbar Cookie
	jQuery('#topBarCollapse').on('hidden.bs.collapse', function(e) {
		Cookies.set('co_topbar', 'set', { expires: 1 });
	});

	let width = window.innerWidth;

	if(width > 500) {
		jQuery('[rel="popover"]').popover({
			container: 'body',
			html: true
		})
	}

	
});

jQuery.fn.exists = function (callback) {
	var args = [].slice.call(arguments, 1);

	if (this.length) {
		callback.call(this, args);
	}

	return this;
};
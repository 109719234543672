//Sticky Header
// export function resizeContent(distance) {
// 	jQuery('.site-main').css('margin-top', distance);
// 	jQuery('.page-homepage .showcase').exists(function () {
// 		jQuery('.slide-item').css('max-height', 'calc(100vh - ' + distance + 'px)');
// 	});
// }

jQuery(document).ready(function (jQuery) {
	let sticky = jQuery('.fixed-top');
	jQuery(window).scroll(function () {
		let scroll = jQuery(window).scrollTop();
		if (scroll >= 60) {
			sticky.addClass('stuck');
		} else {
			sticky.removeClass('stuck');
		}
	});

	// let headerHeight = jQuery('header').height();
	// let topBarHeight = jQuery('#topBarCollapse').height();
	// let distance = headerHeight;
	
	// if(jQuery('#topBarCollapse.show').length) {		
	// 	distance = headerHeight + topBarHeight;
	// }

	// let logo = jQuery('#header-logo');
	// if (jQuery(logo).complete) {
	// 	resizeContent(distance);
	// } else {
	// 	jQuery(logo).on('load', resizeContent(distance));
	// }

	// jQuery('#topBarCollapse').on('shown.bs.collapse', function(e) {
	// 	resizeContent(headerHeight + topBarHeight);
	// });

	// jQuery('#topBarCollapse').on('hidden.bs.collapse', function(e) {
	// 	resizeContent(headerHeight);
	// });
});